<template>
	<r-data-list
		:headdata="headdata"
		:listdata="listData"
		:opt="opts"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		:selectedkey="
			v => {
				return v.id === userId
			}
		"
		:selectedstyle="{ backgroundColor: 'aquamarine' }"
	>
		<template v-slot:rowextrabtn="{ datarow }">
			<r-btn @click="openAddNote(datarow)">Ders Notu Ata</r-btn>
		</template>
	</r-data-list>
	<r-panel
		:title="subListInfo.name + ' için Ders Notları'"
		v-if="subCatPanel"
		v-model:isactive="subCatPanel"
		classes="r-flx-dw-80"
		pos="center"
	>
		<r-flex>
			<r-input v-model="qata" label="Ara" width="50%" />
		</r-flex>
		<r-data-list
			:headdata="headdatacat"
			:listdata="filteredNotes"
			v-if="loaded"
			selectable
			selectval="id"
			v-model:selected="selectedLines"
		/>
		<r-flex>
			<r-btn bgcolor="#15880d" @click="saveUserNote" :disabled="isSaving">
				Kaydet
			</r-btn>
		</r-flex>
	</r-panel>
</template>
<script>
	import { onBeforeMount, ref, computed } from 'vue'
	import { useStore } from 'vuex'
	import { trToUpper } from '@/mix/global'
	export default {
		setup() {
			const store = useStore()
			const listData = ref([])
			const branchList = ref([])
			const notesList = ref({})
			const subCatPanel = ref(false)
			const subListInfo = ref({})
			const brachId = ref(null)
			const userId = ref(null)
			const loaded = ref(false)
			const selectedLines = ref(new Array())
			const userData = ref({})
			const isSaving = ref(false)
			const q = ref('')
			const qata = ref('')
			const searchfilter = v => {
				return (
					trToUpper(v.name).includes(trToUpper(q.value)) ||
					trToUpper(v.branchName).includes(trToUpper(q.value)) ||
					trToUpper(v.typeName).includes(trToUpper(q.value))
				)
			}
			const noteFilter = v => {
				return trToUpper(v.name).includes(trToUpper(qata.value))
			}

			const headdata = computed(() => {
				return [
					{
						title: 'Eğitmen Adı',
						style: { width: '35%' },
						key: 'name',
					},
					{
						title: 'Branş',
						style: { width: '40%' },
						key: 'branchName',
					},
					{
						title: 'Görev',
						style: { width: '20%' },
						key: 'typeName',
					},
				]
			})
			const headdatacat = computed(() => {
				return [
					{
						title: 'Not',
						style: { width: '95%' },
						key: 'name',
					},
				]
			})
			const filteredNotes = computed(() => {
				let list = notesList.value.data.filter(noteFilter)
				return list
			})
			const opts = ref({
				title: 'Eğitmenler',
				buttons: {
					style: { width: '15%' },
				},
			})
			const getList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getteacherlist',
				})
				return list
			}
			/*const getNotesList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotesbycategory',
					params: {
						categoryid: brachId.value,
						isActive: true,
					},
				})
				return list
			}*/
			const geAllNotes = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getallnotes',
					params: {
						isActive: true,
					},
				})
				return list
			}
			const getUserNotesList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotesforuser',
					params: {
						userid: userId.value,
						isActive: true,
					},
				})
				return list
			}
			const openAddNote = async d => {
				qata.value = ''
				selectedLines.value = []
				subListInfo.value = d
				brachId.value = d.branchId
				userId.value = d.id
				userData.value = await getUserNotesList()
				if (store.state.usertype === 0 || store.state.usertype === 1)
					notesList.value = await geAllNotes()
				else if (store.state.usertype === 2) notesList.value = await geAllNotes()

				if (userData.value.data) {
					userData.value.data.forEach(itm => {
						selectedLines.value.push(itm.id)
					})
				} else {
					selectedLines.value = []
				}
				subCatPanel.value = true
			}

			const saveUserNote = async () => {
				isSaving.value = true
				await store.dispatch('postDatas', {
					api: 'notes/assignnote',
					data: {
						UserId: userId.value,
						Ids: selectedLines.value,
					},
				})
				subCatPanel.value = false
				isSaving.value = false
			}
			const getBranchList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getsubcategories',
					params: {
						pid: 0,
					},
				})
				return list
			}
			onBeforeMount(async () => {
				let list = await getList()
				let branchData = await getBranchList()

				branchData.data.forEach(b => {
					branchList.value.push(b.id)
				})
				list.data.forEach(d => {
					if (store.state.usertype === 0 || store.state.usertype === 1)
						if (branchList.value.indexOf(d.branchId) >= 0) listData.value.push(d)
					if (store.state.usertype === 2) {
						if (branchList.value.indexOf(d.branchId) >= 0) listData.value.push(d)
					}
				})
				loaded.value = true
			})
			return {
				headdata,
				headdatacat,
				opts,
				listData,
				filteredNotes,
				saveUserNote,
				subCatPanel,
				openAddNote,
				subListInfo,
				loaded,
				selectedLines,
				q,
				qata,
				searchfilter,
				userId,
				isSaving,
			}
		},
	}
</script>
